.sidebar {
}

.sidebar .sidebar-item,
.sidebar .sidebar-item-active {
  margin: 5px 0px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: white;
  background-color: var(--primary);
}

.sidebar-item:hover {
  opacity: 0.8;
  transition: 0.4s;
  background-color: var(--contrast);
  cursor: pointer;
}

.sidebar-item-active {
  background-color: var(--contrast) !important;
}
