.main-container {
}
.client-logo {
  width: 300px !important;
  margin: 20px auto;
}

.user-info {
  flex-direction: column;
  padding: 10px;
  width: 90%;
  margin: 10px;
  border-radius: 10px;
}

.user-info input {
  background-color: var(--primary-color) !important;
  color: white;
}

.ach-input input,
.ach-input select {
  background-color: transparent !important;
  color: var(--primary-color);
  height: 30px;
  border: 1px solid var(--primary-color);
}

.user-info input:focus {
  --input-bd-focus: var(--primary-color) !important;
}

.payment-method-container {
  margin-top: 10px;
  justify-content: space-between;
  width: 100%;
}

.payment-method {
  width: 150px !important;
}
.payment-method-icon {
  font-size: 20px;
}

.field-container {
  width: 30%;
  padding: 5px;
}

.payment-fields-container {
  flex-direction: row;
}

#cvv,
#expiration-date,
#card-number {
  border: 1px solid #150e5e;
  border-radius: 5px;
  padding: 5px;
  height: 30px !important;
  font-size: 18px !important;
}

.ach-account-holder,
.ach-numbers-holder {
  flex-direction: column;
  width: 50%;
  justify-content: flex-start;
}

.ach-numbers-holder {
  justify-content: flex-end;
}

.ach-numbers-holder .field-container {
  width: 90%;
  align-self: flex-end;
  justify-self: flex-end;
}

.ach-account-holder .field-container {
  width: 90%;
}

#paypal-button {
}
@media screen and (max-width: 768px) {
  .client-logo {
    width: 200px !important;
  }
  .user-info {
    width: 95%;
  }

  .payment-method-container {
    width: 100%;
    flex-direction: column;
  }

  .payment-method {
    width: 100% !important;
    text-align: left;
    margin-top: 5px;
  }

  .payment-fields-container {
    flex-direction: column;
  }

  .field-container {
    width: 100%;
  }

  .ach-numbers-holder,
  .ach-account-holder,
  .ach-numbers-holder .field-container,
  .ach-account-holder .field-container {
    width: 100%;
    margin: 0;
  }

  .pay-btn {
    width: 100% !important;
  }
}

@media screen and (min-width: 1080px) {
  .payment-method-container {
    width: 90%;
  }
}

@media screen and (min-width: 1920px) {
  .payment-method-container {
    width: 60%;
  }
}
