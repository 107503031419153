@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");

.home-container {
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: "Jost";
  font-size: 18px !important;
}

.home-container h1 {
  text-align: center;
}

.text-container {
  width: 50%;
  text-align: center;
  font-size: 20px;
}

.button-container {
  width: 100%;
  margin-top: 20px;
  height: 30%;
  align-items: center;
  justify-content: flex-start;

  flex-direction: column;
}

.option-btn {
  width: 30% !important;
  height: 20% !important;
  font-size: 20px !important;
}

@media screen and (max-width: 768px) {
  .text-container {
    width: 100%;
  }

  .option-btn {
    width: 70% !important;
    height: 20% !important;
    font-size: 20px !important;
  }
}
