.profile-container {
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row; /* Keep this for larger screens */
  background-color: #f3f9fe;
}

.data-container {
  width: 48%; /* Adjusts for larger screens */
  flex-direction: column;
  align-items: center;
}

.communication-cards-container {
  width: 100%;
  justify-content: space-between;
}

.small-card {
  width: 48%; /* Adjusts for larger screens */
}

.field-container {
  width: 30%;
  padding: 5px;
}

#cvv,
#expiration-date,
#card-number {
  border: 1px solid #150e5e;
  border-radius: 5px;
  padding: 5px;
  height: 30px !important;
  font-size: 18px !important;
}

@media screen and (max-width: 768px) {
  .profile-container {
    flex-direction: column; /* Stack items in a column */
    justify-content: flex-start;
  }

  .data-container {
    width: 100%; /* Make full width on smaller screens */
    margin-bottom: 20px; /* Optional: add some space between the containers */
    flex-direction: column !important; /* Ensure the items stack correctly */
  }

  .communication-cards-container {
    width: 100%; /* Ensure this takes full width on smaller screens */
    flex-wrap: wrap; /* Allow wrapping of cards */
  }

  .small-card {
    width: 100%; /* Make full width on smaller screens */
  }

  .small-card:last-child {
    margin-bottom: 20px;
  }
}
