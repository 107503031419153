input:focus {
  --input-bd-focus: #6753db !important;
}

button:disabled {
  background-color: gray !important;
}
.home-btn:hover {
  opacity: 0.7;
  cursor: pointer;
}

.action-icon {
  color: #6753db;
}

.action-icon:hover {
  opacity: 0.7;
  cursor: pointer;
}
